window.goal = function (goal) {
    if (window.Ya && window.Ya._metrika) {
        window.Ya._metrika.counter.reachGoal(goal);
    }
};
//
// document.body.addEventListener('click', function(e) {
//     let closest = e.target.closest('[data-goal-click]');
//     if (closest) {
//         _proceedDataGoal(closest, 'goalClick');
//     }
// });
//
// document.body.addEventListener('click', function(e) {
//     let closest = e.target.closest('[data-goal-click]');
//     if (closest) {
//         _proceedDataGoal(closest, 'goalClick');
//     }
// });
//
// function _proceedDataGoal(element, datasetAttribute) {
//     let goalValue = element.dataset[datasetAttribute];
//     delete element.dataset[datasetAttribute];
//     goal(goalValue);
// }


class Goal {
    constructor(options) {
        this.options = {
            button: '[data-goal-click]',
        };

        const links = document.querySelectorAll(this.options.button);
        this.bind(links);
    }

    bind(links) {
        links.forEach((link) => {
            if (link.dataset.goalClick) {
                link.addEventListener('click', (e) => {
                    e.preventDefault();
                    this.proceedDataGoal(link, 'goalClick' )
                    if (link.hasAttribute('href') && link.href) {
                        if (link.getAttribute("target") === '_blank') {
                            window.open(link.href, '_blank');
                        } else {
                            window.location.href = link.href;
                        }
                    }
                })
            }
        });
    }

    proceedDataGoal(element, datasetAttribute) {
        let goalValue = element.dataset[datasetAttribute];
        delete element.dataset[datasetAttribute];
        if (window.Ya && window.Ya._metrika) {
            window.Ya._metrika.counter.reachGoal(goal);
        }
    }
}

document.addEventListener('DOMContentMutated', () => {
    new Goal();
});

document.addEventListener('DOMContentLoaded', () => {
    new Goal();
});





